@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@600&display=swap');

*{
    box-sizing: border-box;
}
  
body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    position: relative;
}
.home{
    position: relative;
}

.navbar{
    width: 100%;
    height: 15vh;
    display: flex;
    position: sticky;
    top: 0;
    background-color: transparent;
    z-index: 10;

    .head1{
        display: flex;
        width: 30%;
        height: 100%;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;

        h1{ 
            font-size: 20px;
            color: #dc143c;
            display: flex;
            flex-direction: column;
            letter-spacing: 1ch;
            font-family: 'Montserrat Alternates', sans-serif;
            span{
                font-size: 43px;
                color: #003893;
                font-weight: bolder;
            }
        }
    }

    .head2{
        width: 70%;
        height: 100%;
        display: flex;align-items: center;
        background-color: transparent;
        padding: 0 7% 0 0;
        
        .links{
            width: 100%;
            display: flex;
            justify-content: right;

            a{
                text-decoration: none;
                color: #dc143c;
                margin: 0 2%;
                font-weight: bolder;
            }
        }
    }
}

.navbarChange{
    width: 100%;
    height: 15vh;
    display: flex;
    position: sticky;
    top: 0;
    background-color: rgb(0, 0, 0);
    z-index: 10;

    .head1{
        display: flex;
        width: 30%;
        height: 100%;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;

        h1{ 
            font-size: 20px;
            color: #ffffff;
            display: flex;
            flex-direction: column;
            letter-spacing: 1ch;
            font-family: 'Montserrat Alternates', sans-serif;
            span{
                font-size: 43px;
                color: #ffffff;
                font-weight: bolder;
            }
            span:hover{
                color: #dc143c;
            }
        }
        h1:hover{
            color: #003893;
        }
    }

    .head2{
        width: 70%;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: transparent;
        padding: 0 7% 0 0;
        
        .links{
            width: 100%;
            display: flex;
            justify-content: right;

            a{
                text-decoration: none;
                color: #ffffff;
                margin: 0 2%;
                font-weight: bolder;
            }
            a:hover{
                color: #dc143c;
            }
        }
    }
}


//section1//
.A{
    margin: 0;
    top: 0;
    width: 100%;
    height: 85vh;
    

    .para{
        background-image: url(./img/welcome.png);
        background-repeat: no-repeat;
        background-size: contain; 
        background-position: 700px 78px;
        box-sizing: border-box;
        width: 100%;
        height: 96vh;
        position: relative;
        background-attachment: fixed;

        .welcome{
            width: 50%;
            height: 100%;
            position: relative;
            font-size: 2rem;
    
            .text{
                margin-top: 20%;
                position: absolute;
                left: 21%;
                height: auto;
                background-image: linear-gradient(to right, rgba(145, 83, 83, 0.397), #003893b9);
                color: #dc143cef;
                padding: 10% 5%;
                border-right: 1rem solid;
                border-radius: 50%;
               
            }

        }
    }

}

//section1//
.B{
    margin-top: 5%;
    background-image: url(https://img.freepik.com/free-vector/realistic-studio-lights-empty-background-design_1017-27233.jpg?size=626&ext=jpg&ga=GA1.2.871503001.1640476800);
    background-size: cover;
    background-repeat: no-repeat; 
    background-position: 0px 0px;
    height: 200vh;
    background-attachment: fixed;
    padding: 15vh 0 0 5%;

    .tshirthead{
        text-align: center;
        font-family: 'Montserrat Alternates', sans-serif;
        font-size: 2rem;
        color: #003893;
    }

    div.gallery {
        margin: 2% 2%;
        border: 1px solid #ccc;
        float: left;
        width: 20%;
      }
      
      div.gallery:hover {
        border: 1px solid #777;
        transform: scaleX(200px);
      }
      
      div.gallery img {
        width: 100%;
        height: auto;
      }
      
      div.desc {
        padding: 15px;
        text-align: center;
      }
}

//section2//

//section2//


//section3//
//section3//


//section4//
//section4//